import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from '../../components/Layout/Layout';
import { Hero2 } from '../../components/Sales/React/Hero2Bundle';
import { Hero3 } from '../../components/Sales/React/Hero3Bundle';
import { Hero4 } from '../../components/Sales/React/Hero4Bundle';
import { Hero5 } from '../../components/Sales/React/Hero5';
import { FeaturedIn } from '../../components/FeaturedIn/FeaturedIn';
import { Pricing } from '../../components/Sales/React/Pricing';
import FAQ from '../../components/Sales/React/Faq';

const Sales = () => {

  return (
    <Layout>
      <Helmet>
        <title>Summer End Sales - Bootstrap UI Kit Library and admin template</title>
        <meta
          name="title"
          content="Summer End Sales - Bootstrap UI Kit Library and admin template"
        />
        <meta
          name="description"
          content="Get Contrast Bootstrap UI Kit Library and admin template at a discounted price for only 72 hours"
        />
        <meta
          property="og:title"
          content="Summer End Sales - Bootstrap UI Kit Library and admin template"
        />

        <meta
          property="og:description"
          content="Get Contrast Bootstrap UI Kit Library and admin template at a discounted price for only 72 hours"
        />
        <meta
          property="twitter:title"
          content="Summer End Sales - Bootstrap UI Kit Library and admin template"
        />
        <meta
          property="twitter:description"
          content="Get Contrast Bootstrap UI Kit Library and admin template at a discounted price for only 72 hours"
        />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@devwares" />
        <meta name="twitter:creator" content="@devwares" />
      </Helmet>
      <div> 
        <Hero5/>
        <FeaturedIn/>
        <Hero2/>
        <Hero3/>
        <Hero4/>
        <Pricing/>
        <FAQ />
      </div>
    </Layout>
  );
};

export default Sales;
