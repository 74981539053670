import React from 'react';
import { Link } from 'gatsby';

import '../../../styles/scss/style.scss'

export const Hero5 = () => {

  return (
    <div className="" style={{minHeight:"calc(100vh - 85px)", marginTop:"20px"}}>
      <div  className="container">
        <div className="hero">
          <div className="hero__wrap">
            <div className="hero__header" style={{marginBottom: "25px"}}>
              <h1 className="">
                Bundle
              </h1>
              <h2 className="hero__lead">
                3 in 1 Package
              </h2>
              <p className="hero__text" style={{marginBottom:"0px"}}>
                Get the full capabilties and features of the contrast bootstrap library <br/> with an additional admin template which features
                over 10000+ component variants.
              </p>
              <div className="hero__buttons mt-4">
                <Link href="https://windframe.devwares.com" className="btn1 hero__btn btn__inverse">
                  <span className="btn__text">View Offer</span>
                </Link>
              </div>
            </div>
            <div className="hero__image" style={{width: "90%", marginTop: "0px"}}>
              <img alt="summer sale bootstrap" style={{width: "inherit"}} src={require('../../../components/images/heroImage.png')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
