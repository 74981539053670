import React, { Fragment, useState } from 'react';

import '../../../styles/scss/style.scss'

const planPrice = {
  annually: {
    price: '160',
  },
  monthly: {
    price: '18',
  },
};

export const Pricing = () => {
  const openPaymentOverlay = productType => {
    const paddle = window.Paddle;
    let productId;

    switch (productType) {
      case 'personal':
        productId = 745177;
        break;
      case 'team':
        productId = 745178;
        break;
      case 'enterprise':
        productId = 745179;
        break;
      default:
        productId = 0;
    }

    paddle.Checkout.open({
      product: productId,
      success: 'https://devwares.com/windframe/thankyou',
    });
  };

  // const openPaymentOverlay2 = () => {
  //   const paddle = window.Paddle;
  //   const planId = subDuration === 'monthly' ? 673310 : 673312;
  //   paddle.Checkout.open({ product: planId, success: 'https://devwares.com/windframe/thankyou' });
  // };

  return (
    <Fragment>
      <div className="container" style={{ marginTop: '10rem' }} id="pricing">
        <div className="comparison">
          <div className="comparison__title">
            <h2 className="comparison__lead">Choose the Best Plan for you</h2>
            <p className="comparison__sub-title text-center">
              <br /> <strong>We are currently running limited sale </strong>
            </p>
          </div>
          <div className="comparison__wrap" style={{ padding: '0rem 0.5%' }}>
            <div className="comparison__box bg-dark text-light">
              <div className="d-flex align-items-center">
                <span className="comparison__price">$109</span>
                <span className="ml-2">
                  /<strike>$590</strike>
                </span>
                <span className="sale-price"> Sale Price</span>
              </div>
              <h2 className="comparison__package-name">Personal</h2>
              <p>Great for personal use and for your side projects.</p>
              <ul className="comparison__list2">
                <li className="good">Personal License</li>
                <li className="good">React PRO UI Kit Library</li>
                <li className="good">Angular PRO UI Kit Library</li>
                <li className="good">Bootstrap 5 PRO UI Kit Library</li>
                <li className="good">Full documentation</li>
                <li className="good">10000+ Components</li>
                <li className="good">27+ example Pages</li>
                <li className="good">Custom PRO Components</li>
                <li className="good">Multilevel Sidebar</li>
                <li className="good">Admin Dashboards</li>
                <li className="good">About Pages</li>
                <li className="good">Chat Page</li>
                <li className="good">Lifetime Premium Support</li>
                <li className="good">Lifetime Free Updates</li>
                <li className="good">VIP Early access</li>
              </ul>
              <button
                onClick={() => openPaymentOverlay('personal')}
                data-gumroad-single-product="true"
                className="btn1 btn__inverse btn__full"
              >
                Pick for Personal use
              </button>
            </div>
            <div className="comparison__box bg-dark text-light">
              <div className="d-flex align-items-center">
                <div className="comparison__price">$329</div>
                <span className="ml-2">
                  /<strike>$979</strike>
                </span>
                <span className="sale-price"> Sale Price</span>
              </div>
              <h2 className="comparison__package-name">Startup</h2>
              <p>Great for Startup Company/Projects </p>
              <ul className="comparison__list2">
                <li className="good">Team License</li>
                <li className="good">Team size: 1 - 10</li>
                <li className="good">React PRO UI Kit Library</li>
                <li className="good">Angular PRO UI Kit Library</li>
                <li className="good">Bootstrap 5 PRO UI Kit Library</li>
                <li className="good">Full documentation</li>
                <li className="good">10000+ Components</li>
                <li className="good">27+ example Pages</li>
                <li className="good">Custom PRO Components</li>
                <li className="good">Multilevel Sidebar</li>
                <li className="good">Admin Dashboards</li>
                <li className="good">About Pages</li>
                <li className="good">Chat Page</li>
                <li className="good">Lifetime Premium Support</li>
                <li className="good">Lifetime Free Updates</li>
                <li className="good">VIP Early access</li>
              </ul>
              <button
                onClick={() => openPaymentOverlay('team')}
                data-gumroad-single-product="true"
                className="btn1 btn__inverse btn__full"
              >
                Grab this
              </button>
            </div>
            <div className="comparison__box bg-dark text-light">
              <div className="d-flex align-items-center">
                <div className="comparison__price">$579</div>
                <span className="ml-2">
                  /<strike>$1669</strike>
                </span>
                <span className="sale-price"> Sale Price</span>
              </div>
              <h2 className="comparison__package-name">Enterprise</h2>
              <p>Best for large scale uses and extended redistribution rights.</p>
              <ul className="comparison__list2">
                <li className="good">Small Business License</li>
                <li className="good">Team size: Unlimited</li>
                <li className="good">React PRO UI Kit Library</li>
                <li className="good">Angular PRO UI Kit Library</li>
                <li className="good">Bootstrap 5 PRO UI Kit Library</li>
                <li className="good">Full documentation</li>
                <li className="good">10000+ Components</li>
                <li className="good">27+ example Pages</li>
                <li className="good">Custom PRO Components</li>
                <li className="good">Multilevel Sidebar</li>
                <li className="good">Admin Dashboards</li>
                <li className="good">About Pages</li>
                <li className="good">Chat Page</li>
                <li className="good">Lifetime Premium Support</li>
                <li className="good">Lifetime Unlimited Updates</li>
                <li className="good">VIP Early access</li>
              </ul>
              <button
                onClick={() => openPaymentOverlay('enterprise')}
                data-gumroad-single-product="true"
                className="btn1 btn__inverse btn__full"
              >
                Grab this
              </button>
            </div>
          </div>
        </div>
        <p className="comparison__footer-info">* Secured payment through Gumroad</p>
      </div>
    </Fragment>
  );
};
