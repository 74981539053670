import React from 'react';

import '../../../styles/scss/style.scss'

export const Hero4 = ({ dark }) => {

  return (
    <div className="container">
      <div className="feature feature-2">
        <div className="feature__wrap">
          <div className="feature__left" style={{color: dark ? "white":"inherit" }}>
            <div className="hero_badge hero_badge3">Angular</div>
            <h2 className="feature__lead feature__lead--1 showcase__lead">
              Angular bootstrap contrast PRO library + PRO admin template
            </h2>
            <p className="feature__text mb-5">
              Get the Angular version of the contrast PRO library plus a PRO admin template
            </p>
            <a target="_blank" rel="noreferrer" href="https://www.devwares.com/product/angular-contrast-pro/" className="btn1 btn__inverse">
              Check it out
            </a>
          </div>
          <div className="feature__right">
            <img
              src={require('../../../components/images/aboutfeatpro.png')}
              alt="#"
              className="feature__img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
